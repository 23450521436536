import React from 'react';

import logoImg from './logo.jpeg'

const LogoFull = () => {
  return (
    <picture>
      <img src={logoImg} width={195} />
    </picture>
  );
};

export default LogoFull;
