import { FieldValidation } from '@rjsf/utils';
import { FieldExtensionOptions } from '@backstage/plugin-scaffolder-react';

import { GenericTextField } from './GenericTextField';

export const validateSandboxName = (
  value: string,
  validation: FieldValidation,
) => {
  const nameMatch = /^[a-zA-Z]+(?:-[a-zA-Z]+)*-\d{2}$/g.test(value);

  if (nameMatch === false) {
    validation.addError(
      `Only letters, numbers and hyphen ("-") are allowed. Please use this format: "john-doe-01".`,
    );
  }
};

export const sandboxNameField: FieldExtensionOptions<string, any> = {
  name: 'SandboxName',
  component: GenericTextField,
  validation: validateSandboxName,
};