import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const AOTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#00342B',
      },
      secondary: {
        main: '#009B55',
      },
      status: {
        ok: '#009B55',
        warning: '#DEAB16',
        error: '#FF5F46',
        running: '#3F80DA',
        pending: '#EAC868',
        aborted: '#CCD6D5',
      },
      background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
      },
      navigation: {
        background: '#FFFFFF',
        indicator: '#009B55',
        color: '#00342B',
        selectedColor: '#009B55',
        navItem: {
          hoverBackground: '#CCEBDD',
        },
        submenu: {
          background: '#CCEBDD',
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Roboto, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({
      colors: ['#00342B', '#009B55'], shape: shapes.wave
    }),
    documentation: genPageTheme({
      colors: ['#00342B', '#009B55'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#00342B', '#009B55'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#00342B', '#009B55'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#00342B', '#009B55'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#00342B', '#009B55'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#00342B', '#009B55'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#00342B', '#009B55'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#00342B', '#009B55'], shape: shapes.wave }),
  },
  components: {
    BackstageSidebar: {
      styleOverrides: {
        drawer: () => ({
          border: `1px solid #00342B`
        }),
      },
    },
  },
});