import React from 'react';
import { AppTheme } from "@backstage/core-plugin-api";
import { UnifiedTheme, UnifiedThemeProvider, themes } from "@backstage/theme";
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/Brightness2';

import { AOTheme } from './AOTheme';

const createProvider =
  (theme: UnifiedTheme): AppTheme["Provider"] =>
    ({ children }) => {
      return (
        <UnifiedThemeProvider theme={theme}>{children}</UnifiedThemeProvider>
      );
    };

export const getThemes = (): AppTheme[] => [
  {
    id: "ao-light",
    title: "A&O Light",
    variant: "light",
    icon: <LightIcon />,
    Provider: createProvider(AOTheme),
  },
  {
    id: "backstage-light",
    title: "Backstage Light",
    variant: "light",
    icon: <LightIcon />,
    Provider: createProvider(themes.light),
  },
  {
    id: "backstage-dark",
    title: "Backstage Dark",
    variant: "dark",
    icon: <DarkIcon />,
    Provider: createProvider(themes.dark),
  },
];
