import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

export class AOTechRadar implements TechRadarApi {
  // @ts-ignore
  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    return {
      quadrants: [
        { id: 'infrastructure', name: 'Infrastructure' },
        { id: 'platforms', name: 'Platforms' },
        { id: 'tools', name: 'Tools' },
        { id: 'languages', name: 'Languages & Frameworks' },
      ],
      rings: [
        { id: "use", name: "Use", color: "#5BA300" }, // recommended for use by the majority of teams
        { id: "trial", name: "Trial", color: "#009EB0" }, // evaluated for specific use cases and has showed clear benefits
        { id: "assess", name: "Assess", color: "#C7BA00" }, // has the potential to be beneficial for the company
        { id: "hold", name: "Hold", color: "#E09B96" }, // we don’t want to further invest in this technology
      ],
      // @ts-ignore
      entries: [
        // Languages
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          url: "https://dotnet.microsoft.com/en-us/platform/support/policy/dotnet-core",
          key: "C#",
          id: "C#",
          title: "C#",
          quadrant: "languages",
          description: "**Min version**: .NET 6 LTS\n\n**Preferred version**: .NET 8 LTS\n\n[.NET support lifecycle](https://dotnet.microsoft.com/en-us/platform/support/policy/dotnet-core)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          url: "https://en.wikipedia.org/wiki/ECMAScript",
          key: "JavaScript",
          id: "JavaScript",
          title: "JavaScript",
          quadrant: "languages",
          description: "**Min version**: ES5\n\n**Preferred version**: >= ES6 (ECMAScript 2015)\n\n[ECMA Script wikipedia entry](https://en.wikipedia.org/wiki/ECMAScript)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "TypeScript",
          id: "TypeScript",
          title: "TypeScript",
          quadrant: "languages",
          description: "**Min version**: 4.3.x\n\n**Preferred version**: >= 5.0.0\n\n[Releases](https://github.com/Microsoft/TypeScript/releases)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Python",
          id: "Python",
          title: "Python",
          quadrant: "languages",
          description: "**Min version**: 3.8.0\n\n**Preferred version**: >= 3.11.0\n\n[Status of versions](https://www.python.org/doc/versions/)"
        },
        // Scripting
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "PowerShell",
          id: "PowerShell",
          title: "PowerShell",
          quadrant: "languages",
          description: "**Min version**: 7.2\n\n**Preferred version**: >= 7.3.0\n\n[PowerShell Support lifecycle](https://docs.microsoft.com/en-us/powershell/scripting/developer/compatibility/compatibility?view=powershell-7.2)"
        },
        // JavaScript Frameworks
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "AngularJS",
          id: "AngularJS",
          title: "AngularJS",
          quadrant: "languages",
          description: "**Min version**: 14\n\n**Preferred version**: >= 16.x.x\n\n[Angular support lifecycle](https://angular.io/guide/releases)"
        },
        // Package Managers
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "NuGet",
          id: "NuGet",
          title: "NuGet",
          quadrant: "tools",
          description: "**Language**: C#\n\n[NuGet Gallery](https://www.nuget.org/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "npm",
          id: "npm",
          title: "npm",
          quadrant: "tools",
          description: "**Language**: JavaScript\n\n[NPM Packages](https://www.npmjs.com/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Conda",
          id: "Conda",
          title: "Conda",
          quadrant: "tools",
          description: "**Language**: Python\n\n[Anaconda](https://anaconda.org/anaconda/conda)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Pip",
          id: "Pip",
          title: "Pip",
          quadrant: "tools",
          description: "**Language**: Python\n\n[PyPy](https://pypi.org/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "PowerShell Gallery",
          id: "PowerShell Gallery",
          title: "PowerShell Gallery",
          quadrant: "tools",
          description: "**Language**: PowerShell\n\n[PowerShell Gallery](https://www.powershellgallery.com/)"
        },
        // Testing frameworks
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Playwright",
          id: "Playwright",
          title: "Playwright",
          quadrant: "languages",
          description: "Web application testing\n\n[Playwright](https://playwright.dev/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Selenium",
          id: "Selenium",
          title: "Selenium",
          quadrant: "languages",
          description: "Web application testing\n\n[Selenium](https://www.selenium.dev/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Specflow",
          id: "Specflow",
          title: "Specflow",
          quadrant: "languages",
          description: "C# BDD\n\n[SpecFlow](https://specflow.org/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "NUNIT",
          id: "NUNIT",
          title: "NUNIT",
          quadrant: "languages",
          description: "C# Unit testing\n\n[NUNIT](https://nunit.org/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Pester",
          id: "Pester",
          title: "Pester",
          quadrant: "languages",
          description: "PowerShell testing\n\n[Pester](https://pester.dev/)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "PyTest",
          id: "PyTest",
          title: "PyTest",
          quadrant: "languages",
          description: "Python functional tests\n\n[PyTest](https://docs.pytest.org/en/7.3.x/)"
        },
        // Infrastructure
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "bicep",
          id: "bicep",
          title: "Bicep",
          quadrant: "infrastructure",
          description: "Bicep is a domain-specific language (DSL) that uses declarative syntax to deploy Azure resources\n\n[Bicep](https://learn.microsoft.com/en-us/azure/azure-resource-manager/bicep/overview?tabs=bicep)"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "hold",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "ado",
          id: "ado",
          title: "Azure DevOps",
          quadrant: "infrastructure",
          description: "Azure DevOps - deprecated, migrate throughout 2023 to GitHub Enterprise"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "github",
          id: "github",
          title: "GitHub Enterprise",
          quadrant: "infrastructure",
          description: "GitHub is a developer platform that allows developers to create, store, manage and share their code"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "github_actions",
          id: "github_actions",
          title: "GitHub Actions",
          quadrant: "infrastructure",
          description: "For new projects use GitHub actions for existing projects, migrate from Azure DevOps pipelines (YAML) to GitHub Actions"
        },
        // Tools
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "ansible",
          id: "ansible",
          title: "Ansible",
          quadrant: "tools",
          description: "Open source IT automation engine"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "durable_func",
          id: "durable_func",
          title: "Azure durable functions",
          quadrant: "tools",
          description: "Automated deployments"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "logic_apps",
          id: "logic_apps",
          title: "Azure Logic Apps",
          quadrant: "tools",
          description: "Automated deployments"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "mend",
          id: "mend",
          title: "Mend",
          quadrant: "tools",
          description: "Software Composition Analysis (SCA) - including container scanning\n\nSoftware Application Security Testing (SAST)\n\nUse the Mend unified CLI within pipelines\n\nMend repository scanning is enabled in GitHub"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "sql_studio",
          id: "sql_studio",
          title: "SQL Server Management Studio",
          quadrant: "tools",
          description: "SQL Server Management Studio"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "Postman",
          id: "Postman",
          title: "Postman",
          quadrant: "tools",
          description: "exploratory API testing"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "storage_expl",
          id: "storage_expl",
          title: "Azure Storage Explorer",
          quadrant: "tools",
          description: "Azure Storage Explorer"
        },
        {
          timeline: [
            {
              moved: 0,
              ringId: "use",
              date: new Date('2023-11-13'),
              description: "Approved"
            }
          ],
          key: "data_expl",
          id: "data_expl",
          title: "Azure Data Explorer",
          quadrant: "tools",
          description: "Azure Data Explorer"
        }
      ],
    };
  }
}