import { FieldValidation } from '@rjsf/utils';
import { FieldExtensionOptions } from '@backstage/plugin-scaffolder-react';

import { GenericTextField } from './GenericTextField';

export const validateSandboxUserList = (
  value: string,
  validation: FieldValidation,
) => {
  const valueMatch = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\s+[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/g.test(value);

  if (valueMatch === false) {
    validation.addError(
      `Only space-separated email list is allowed. Please use this format: "john@doe.com jane@doe.com".`,
    );
  }
};

export const sandboxUserListField: FieldExtensionOptions<string, any> = {
  name: 'SandboxUserList',
  component: GenericTextField,
  validation: validateSandboxUserList,
};